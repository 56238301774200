body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.matrixtable td:first-child {
  width: 8%;
}

.matrixtable td:not(:first-child) {
  width: 1%;
  min-width: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#hero:before {
  background: transparent;
}


@import "./assets/alice-carousel.css";

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}  

.tour-mask {
  border-radius: 32px;
}

#hero .btn-get-started {
  background: #00A0A4;
  border: 2px solid #00A0A4;

}



/*-- Nav --*/


.nav-menu > ul > li > a:before {
  background-color: #00A0A4;
}

.get-started-btn {
  margin-left: 1px;
}

.get-started-btn:hover {
 background: #00A0A4;
 border: #00A0A4;
}

.btn {
  box-sizing: border-box;
  border-width: 1px !important;
  font-size: 18px !important;
  font-weight: 600;
  padding: 0.4rem 13px !important;
  line-height: 1.75;
  cursor: pointer;
  text-transform: uppercase;
  background-color: white !important;
  border-color: white !important;
  color: grey !important;
  opacity: 1;
  filter: alpha(opacity=100);
  transition: all 150ms linear;
  border-radius: 20px !important;
}

.btn-primary {
  margin-right: 10px !important;
}

.fa-align-justify {
  margin-right: 10px;
  margin-left: 8px;
}

